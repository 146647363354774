'use client';

import React, { ReactElement, useEffect, useRef } from 'react';
import { usePaypalMessageContext } from '../context/PaypalMessage';
import { useAppContext } from '@/lib/context';

interface IPayPalMessage {
    amount: number;
    id?: string;
    logoType?: string;
    pageType: string;
    position?: string;
    styleType?: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
declare module globalThis {
    let paypal: any;
    let document: any;
}

const PayPalMessage = ({ amount, id, logoType, pageType }: IPayPalMessage): ReactElement | null => {
    const [
        {
            storeConfig: { websiteCode },
        },
    ] = useAppContext();
    const { isScriptLoaded } = usePaypalMessageContext();

    const messageId = useRef(null);

    useEffect(() => {
        if (!isScriptLoaded || !globalThis.paypal?.Messages || !messageId?.current) return;

        globalThis.paypal
            .Messages({
                amount, //: productPrice.value,
                buyerCountry: websiteCode?.toUpperCase(),
                pageType,
                style: {
                    layout: 'text',
                    logo: {
                        type: logoType || 'inline',
                    },
                },
            })
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            .render(`#${messageId?.current?.id}`);
    }, [amount, isScriptLoaded, pageType, messageId.current, websiteCode]);

    return <div ref={messageId} id={id || `pp-message-${Math.random().toString(36).substr(2, 9)}`} />;
};

PayPalMessage.displayName = 'PayPalMessage';

export default PayPalMessage;
